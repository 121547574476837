import { MapStringString, RouteParams } from '@frontend2/core';
import { environment } from '../../../environments/environment';
import { FACEBOOK_OAUTH_URL, FACEBOOK_REDIRECT_PATH } from '../../core/utils';
import {
  NetworkOAuthService,
  OauthState,
  applyOAuthStateToUrl,
} from './network-oauth.service';

const scopes = [
  'public_profile',
  'pages_read_engagement',
  'pages_show_list',
  'instagram_basic',
  'instagram_manage_insights',
  'business_management',
];

const FACEBOOK_OAUTH_CODE_KEY = 'code';

export const FACEBOOK_OAUTH_ERROR_KEY = 'facebook_oauth_error';

interface OAuthUrlRequest extends Record<string, string> {
  readonly client_id: string;
  readonly redirect_uri: string;
  readonly scope: string;
  readonly auth_type: string;
  readonly extras: string;
  readonly display: string;
}

export class InstagramOAuthService implements NetworkOAuthService {
  buildUrl(redirectUri: URL, args?: { state?: OauthState }): URL {
    const state = args?.state ?? {};

    const url = new URL(FACEBOOK_OAUTH_URL);

    const request: OAuthUrlRequest = {
      client_id: environment.facebookClientId,
      redirect_uri: redirectUri.toString(),
      scope: scopes.join(','),

      // if influencer declined permission, it will request it again
      auth_type: 'rerequest',
      extras: '{"setup":{"channel":"IG_API_ONBOARDING"}}',
      display: 'page',
    };

    url.search = new URLSearchParams(request).toString();

    return applyOAuthStateToUrl(url, state);
  }

  parseCodeFromQueryParams(queryParams: MapStringString): string {
    return queryParams[FACEBOOK_OAUTH_CODE_KEY] ?? '';
  }

  getRedirectURL(): URL {
    return new URL(window.location.origin + '/' + FACEBOOK_REDIRECT_PATH);
  }
}

export function hasFacebookOAuthError(params: RouteParams): boolean {
  return params.queryParameters[FACEBOOK_OAUTH_ERROR_KEY] === 'true';
}
