import { Inject, Injectable } from '@angular/core';
import {
  DocumentSnapshot,
  doc,
  docSnapshots,
  getDoc,
} from '@angular/fire/firestore';
import { StringValue } from '@bufbuild/protobuf';
import { Code } from '@connectrpc/connect';
import {
  BroncoClient,
  BroncoClientProvider,
  BroncoTask,
  BroncoTaskError,
} from '@frontend2/api';
import {
  Disposable,
  Messages,
  Observables,
  isNil,
  isNotNil,
} from '@frontend2/core';
import { Task } from '@frontend2/proto/common/proto/bronco_pb';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  lastValueFrom,
  map,
  of,
  takeWhile,
} from 'rxjs';
import { DEFAULT_TOAST_DURATION, Toast } from '../toast/toast.models';
import { ToastManager } from '../toast/toast.service';
import { FirebaseBloc, FirebaseConnectionState } from './firebase-bloc.service';

type ToastMsgBuilder = (task?: BroncoTask) => string;

const broncoCollection = 'bronco';
const bronco2Prefix = 'bc2:';
const httpPollingPeriod = 3000;

function firebaseDebugUrl(taskId: string): string {
  return `https://console.firebase.google.com/project/leftyv2-1136/firestore/data/~2Fbronco~2F${taskId}`;
}

function broncoDebugUrl(taskId: string): string {
  return `https://api.lefty.io/_apiv3/bronco/task?value=${taskId}`;
}

function logDebugUrl(taskId: string): void {
  console.log(
    '\n' +
      '=====================================================================\n' +
      `BRONCO_TASK_ID: ${taskId}\n` +
      `FIREBASE: ${firebaseDebugUrl(taskId)}\n` +
      `BRONCO: ${broncoDebugUrl(taskId)}\n` +
      '=====================================================================\n' +
      '\n',
  );
}

/**
 * Parse Firebase document snapshot into BroncoTask
 *
 * if snapshot does not exist, the returned task is marked as not found and done
 */
function buildTaskFromFirebase(snapshot: DocumentSnapshot): BroncoTask {
  const data = snapshot.data();

  if (!snapshot.exists() || !data) {
    return {
      done: true,
      worked_on: false,
      handle: snapshot.id,
      status: Code.NotFound,
    };
  }

  const task: BroncoTask = data as BroncoTask;
  return {
    ...task,
    handle: snapshot.id,
  };
}

/**
 * Transofmr a bronco task with multiple attempts,
 * to a regular bronco task containing the last attempt
 */
function buildTaskFromBronco(task: Task): BroncoTask {
  const lastAttempt =
    task.attempts.length === 0
      ? undefined
      : task.attempts[task.attempts.length - 1];

  let firestoreTask: BroncoTask = {
    handle: task.id,
    done: task.isDone,
    worked_on: task.isDone === false && task.attempts.length > 0,
  };

  if (isNotNil(lastAttempt)) {
    if (isNotNil(task.isDone) && isNotNil(lastAttempt.status)) {
      firestoreTask = {
        ...firestoreTask,
        status: lastAttempt.status.code,
        status_description: lastAttempt.status.message,
      };
    }

    if (isNotNil(lastAttempt.progress)) {
      firestoreTask = {
        ...firestoreTask,
        progress: lastAttempt.progress.progress,
        progress_msg: lastAttempt.progress.message,
        progress_metadata: lastAttempt.progress.metadata,
      };
    }

    if (task.isDone && isNotNil(lastAttempt.output)) {
      firestoreTask = {
        ...firestoreTask,
        output: lastAttempt.output,
      };
    }
  }

  return firestoreTask;
}

/**
 * Main bronco service use to subscribe to a bronco task.
 *
 * Use firebase to receive update from backend.
 * If Firebase not available we use the bronco http api that use http polling
 */
@Injectable({ providedIn: 'root' })
export class BroncoService {
  constructor(
    public toastManager: ToastManager,
    private firebaseBloc: FirebaseBloc,
    @Inject(BroncoClientProvider) private broncoClient: BroncoClient,
  ) {}

  // current subscription cache
  private tasks: {
    [key: string]: BroncoTaskSubscription;
  } = {};

  // current subscription cache
  private tasksWithoutToast: {
    [key: string]: BroncoTaskSubscription;
  } = {};

  /// Fetch task by ID using Bronco API
  ///
  /// return [TaskFirestoreStatus] with status not found
  /// if API fail with not found status
  private async fetchBroncoHttpTask(taskId: string): Promise<BroncoTask> {
    const task = buildTaskFromBronco(
      await this.broncoClient.findById(new StringValue({ value: taskId })),
    );

    return task;
  }

  /// Get document on Firebase corresponding to ID
  ///
  /// return [TaskFirestoreStatus] with status not found
  /// if document does not exist
  private async getTaskFromFirebase(taskId: string): Promise<BroncoTask> {
    const ref = doc(this.firebaseBloc.getCollection(broncoCollection), taskId);
    const snapshot = await getDoc(ref);
    return buildTaskFromFirebase(snapshot);
  }

  async observeTask(taskId: string): Promise<Observable<BroncoTask>> {
    if (taskId.startsWith(bronco2Prefix)) {
      taskId = taskId.substring(bronco2Prefix.length);
    }

    logDebugUrl(taskId);

    let task: BroncoTask | undefined;

    // we first check if task exist on firebase
    try {
      task = await this.getTaskFromFirebase(taskId);
    } catch (e) {
      console.warn('Faild to fetch task from firebase', e);
    }

    if (isNil(task) || task.status === Code.NotFound) {
      console.warn(`Task ${taskId} not found on firebase`);
      // if task is not found
      // we fallback on http polling
      return this.observeBroncoTaskHttp(taskId);
    }

    ///don't need to listen firestore if task already done or failed
    const hasFailed = isNotNil(task.status) && task.status !== 0;
    if (task.done === true || hasFailed) {
      return of(task);
    }

    if (
      this.firebaseBloc.currentState.connection !==
      FirebaseConnectionState.connected
    ) {
      /// if no firebase connection
      /// fallback on HTTP call every [httpPollingPeriod]
      return this.observeBroncoTaskHttp(taskId);
    }

    return this.observeBroncoTaskFirebase(taskId);
  }

  private observeBroncoTaskFirebase(taskId: string): Observable<BroncoTask> {
    const docRef = doc(
      this.firebaseBloc.getCollection(broncoCollection),
      taskId,
    );
    return docSnapshots(docRef)
      .pipe(map(buildTaskFromFirebase))
      .pipe(takeWhile((task) => !task.done, true));
  }

  private observeBroncoTaskHttp(taskId: string): Observable<BroncoTask> {
    return Observables.poll(
      () => this.fetchBroncoHttpTask(taskId),
      (task) => !task.done,
      httpPollingPeriod,
    );
  }

  /// Subscribe to a Bronco handle
  /// and display progress, success and error toast
  ///
  /// Use [toastDuration] to determine how much time
  /// error or succes toast are visible.
  /// Pass `null` for unlimited time
  ///
  /// By default, we display a first progress toast before we get any response
  /// from firebase. Yout can disable it using [hideInitialToast]
  ///
  /// You can attach an existing toast using [toast] param,
  /// it will be automatically updated on task change using message builders
  subscribe(
    id: string,
    options?: Partial<BroncoToastOptions>,
  ): BroncoTaskSubscription {
    if (this.tasks[id]) {
      const currentToast = this.tasks[id].toast;
      if (
        options?.toast &&
        currentToast &&
        currentToast.id !== options?.toast.id
      ) {
        this.toastManager.close(currentToast.id);
        this.tasks[id].toast = options.toast;
      }
      return this.tasks[id];
    }

    this.tasks[id] = new BroncoTaskSubscription(this, id, options);
    this.tasks[id].subscribe();
    return this.tasks[id];
  }

  /// Subscribe to task without showing a toast
  ///
  /// Then you can handle task progress externally
  ///
  /// ```dart
  /// const subscription = bronco.subscribeWithoutToast(id);
  ///
  /// subscription.taskChange$.listen(showTaskState);
  /// ```
  subscribeWithoutToast(id: string): BroncoTaskSubscription {
    if (this.tasksWithoutToast[id]) {
      return this.tasksWithoutToast[id];
    }

    this.tasksWithoutToast[id] = new BroncoTaskSubscription(this, id, {
      hideInitial: true,
      hide: true,
    });

    this.tasksWithoutToast[id].subscribe();

    return this.tasksWithoutToast[id];
  }

  waitTask(id: string): Promise<BroncoTask> {
    return this.subscribeWithoutToast(id).wait();
  }

  dispose(): void {
    for (const task of Object.values(this.tasks)) {
      task.dispose();
    }
    this.tasks = {};

    for (const task of Object.values(this.tasksWithoutToast)) {
      task.dispose();
    }
    this.tasksWithoutToast = {};
  }
}

interface BroncoToastOptions {
  successMessage?: ToastMsgBuilder;
  progressMessage?: ToastMsgBuilder;
  errorMessage?: ToastMsgBuilder;

  readonly duration: number;
  readonly hide: boolean;

  /// don't show the first toast
  /// before we establish connection with Firebase
  readonly hideInitial: boolean;

  readonly toast?: Toast;
}

/// Wrapper around the subscription of a bronco task
/// Keep current [task] state up to date
/// and show [Toast] if necessary
///
/// Use [BroncoService] to subscribe to a task
///
/// Then listen or wait for task change
///
/// ```dart
/// const subscription = bronco.subscribeWithoutToast(id);
///
/// // listen for task change
/// subscription.taskChange$.listen(showTaskState);
///
/// // wait for task completion
/// await subscription.wait();
/// ```
export class BroncoTaskSubscription extends Disposable {
  private readonly options: BroncoToastOptions;

  private readonly _taskSubject: BehaviorSubject<BroncoTask>;

  private listener?: Subscription;

  constructor(
    private readonly service: BroncoService,
    private readonly id: string,
    opts?: Partial<BroncoToastOptions>,
  ) {
    super();

    this._taskSubject = new BehaviorSubject<BroncoTask>({
      handle: id,
      worked_on: false,
      done: false,
    });

    this.options = {
      ...opts,
      duration: opts?.duration ?? DEFAULT_TOAST_DURATION,
      hide: opts?.hide ?? false,
      hideInitial: opts?.hideInitial ?? false,
    };

    this.toast = opts?.toast;
  }

  toast?: Toast;

  get toastId(): string {
    return this.toast?.id ?? this.id;
  }

  get toastManager(): ToastManager {
    return this.service.toastManager;
  }

  get task(): BroncoTask {
    return this._taskSubject.value;
  }

  get taskChange$(): Observable<BroncoTask> {
    return this._taskSubject;
  }

  /// Wait for task completion (when [BroncoTask.isDone] is true)
  ///
  /// Emit error if [BroncoTask.hasFailed] is true
  wait(): Promise<BroncoTask> {
    return lastValueFrom(this.taskChange$);
  }

  private showOrUpdateToast(toast: Toast): void {
    if (this.toast) {
      this.toastManager.update(this.toast.id, toast);
    } else {
      this.toastManager.show(toast);
    }

    this.toast = toast;
  }

  /// Start subscription to Task update
  ///
  /// Show toasts if [hideToast] is false
  ///
  /// Return completed Task
  async subscribe(): Promise<void> {
    // Show toast before we establish connection with Firebase
    if (this.options.hideInitial === false) {
      this.showToast(this.task);
    }

    if (this.listener) {
      return;
    }

    const observable = await this.service.observeTask(this.id);

    this.listener = observable.subscribe((task: BroncoTask) => {
      this._taskSubject.next(task);
      this.showToast(task);

      // cleanup streams when task is done
      if (task.done) {
        if (task.status !== undefined && task.status !== 0) {
          this._taskSubject.error(new BroncoTaskError(task));
        }

        this.dispose();
      }
    });

    return;
  }

  override dispose(): void {
    super.dispose();
    this._taskSubject.complete();
    this.listener?.unsubscribe();
    this.toast = undefined;
  }

  private showFailure(task: BroncoTask): void {
    const message = !this.options.errorMessage
      ? Messages.errorHappen
      : this.options.errorMessage(task);
    console.error(message);

    if (this.options.hide) {
      return;
    }

    this.showOrUpdateToast(
      Toast.error(this.toastId, message, {
        duration: this.options.duration,
      }),
    );
  }

  private showSuccess(task: BroncoTask): void {
    const message = !this.options.successMessage
      ? Messages.success
      : this.options.successMessage(task);

    console.log(message);

    if (this.options.hide) {
      return;
    }

    this.showOrUpdateToast(
      Toast.success(this.toastId, message, {
        duration: this.options.duration,
      }),
    );
  }

  private showProgress(task: BroncoTask): void {
    const progress = task.progress ?? 0;
    const message = !this.options.progressMessage
      ? Messages.loading
      : this.options.progressMessage(task);

    console.log(`Progressing ${progress}%: ${message}`);

    if (this.options.hide) {
      return;
    }

    this.showOrUpdateToast(
      Toast.loading(this.toastId, message, {
        // show indeterminate loading state if there is no progress
        progress: task.progress,
      }),
    );
  }

  /// function to display toast depending on the current task state
  private showToast(task: BroncoTask): void {
    if (isNotNil(task.status) && task.status !== 0) {
      this.showFailure(task);
    } else if (task.done === true) {
      this.showSuccess(task);
    } else {
      this.showProgress(task);
    }
  }
}
