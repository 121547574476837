import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export type BadgeColor = 'teal' | 'orange' | 'red' | 'yellow' | 'green' | '';

@Component({
  selector: 'lefty-badge',
  template: '<div class="text"><ng-content></ng-content></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyBadgeComponent {
  @Input()
  color: BadgeColor = '';

  @Input()
  @HostBinding('class.square')
  square = false;

  @HostBinding('class')
  get className(): string {
    return `lefty-badge ${this.color}`;
  }
}
