import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { API_HOST, GrpcModule } from '@frontend2/api';
import { LoggedAuthorV2 } from '@frontend2/proto/librarian/proto/registered_author_pb';
import { LeftyErrorHandlerModule, UiModule } from '@frontend2/ui';
import { environment } from '../environments/environment';
import { NestorAppComponent } from './app.component';
import { AppRouterModule } from './app.routes';
import { AuthComponentsModule } from './common/auth/auth.components.module';
import { AuthBloc } from './core/auth.bloc';

function onAppInit(auth: AuthBloc): () => Promise<LoggedAuthorV2> {
  return () => auth.checkIsLogged();
}

@NgModule({
  declarations: [NestorAppComponent],
  imports: [
    CommonModule,
    UiModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    LeftyErrorHandlerModule,
    GrpcModule,
    AppRouterModule,
    AuthComponentsModule,
  ],
  providers: [
    { provide: API_HOST, useValue: environment.apiHost },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [AuthBloc],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
  ],
  bootstrap: [NestorAppComponent],
})
export class AppModule {}
