import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Output } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ConnectError } from '@connectrpc/connect';
import {
  isNotEmptyString,
  LEFTY_CREATORS_PRIVACY_POLICY_URL,
} from '@frontend2/core';
import { LoggedAuthorV2 } from '@frontend2/proto/librarian/proto/registered_author_pb';
import {
  LeftyButtonDirective,
  LeftyCheckboxComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
} from '@frontend2/ui';
import { captureException } from '@sentry/browser';
import { Subject } from 'rxjs';
import { AuthBloc } from '../../../core/auth.bloc';
import { AuthBaseComponent } from '../auth.helper';

@Component({
  selector: 'signup-component',
  templateUrl: 'signup.component.html',
  styleUrls: ['signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    LeftyFeedbackComponent,
    LeftyFormInputComponent,
    LeftyCheckboxComponent,
    LeftyButtonDirective,
    RouterLink,
  ],
})
export class SignUpComponent extends AuthBaseComponent {
  constructor(
    private _authBloc: AuthBloc,
    _router: Router,
  ) {
    super(_authBloc, _router);

    this.disposer.addSubject(this.signupSuccess$);
  }

  readonly privacyPolicyUrl = LEFTY_CREATORS_PRIVACY_POLICY_URL;

  @Output()
  readonly signupSuccess$ = new Subject<LoggedAuthorV2>();

  errorMessage = '';

  get hasErrorMessage(): boolean {
    return isNotEmptyString(this.errorMessage);
  }

  loading = false;

  acceptTerms = false;

  async submit(form: NgForm): Promise<void> {
    if (form.invalid) {
      return;
    }

    if (!this.acceptTerms) {
      this.setState(() => (this.errorMessage = this._termsErrorMsg));
      return;
    }
    this.setState(() => {
      this.errorMessage = '';
      this.loading = true;
    });

    try {
      await this._authBloc.signup(
        form.controls['email'].value,
        form.controls['password'].value,
      );
      const author = await this._authBloc.checkIsLogged();
      this.signupSuccess$.next(author);
    } catch (e) {
      this._handleSignupError(e);
    } finally {
      this.setState(() => (this.loading = false));
    }
  }

  private isEmailAlreadyExistError(error: unknown): boolean {
    return (
      error instanceof ConnectError &&
      error.message.startsWith('[already_exists]')
    );
  }

  private _handleSignupError(error: unknown): void {
    console.error(error);
    if (this.isEmailAlreadyExistError(error)) {
      this.setState(() => (this.errorMessage = this._emailAlreadyExistMsg));
    } else {
      captureException(error);
      this.setState(() => (this.errorMessage = this._signupErrorMsg));
    }
  }

  private get _emailAlreadyExistMsg(): string {
    return $localize`An account with this email address already exists. Please sign in using this email address or reset the password.`;
  }

  private get _signupErrorMsg(): string {
    return $localize`Sign Up failed`;
  }

  private get _termsErrorMsg(): string {
    return $localize`You should accept Lefty terms of service`;
  }
}
