import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeftyLineClampDirective, UiModule } from '@frontend2/ui';
import { NestorFooterComponent } from './footer/footer.component';
import { NestorMobileHeaderComponent } from './mobile-header/mobile-header.component';
import { NestorExpandableRichTextComponent } from './rich-text/expandable-text.component';
import { NestorRichTextComponent } from './rich-text/text-brief.component';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    LeftyLineClampDirective,
    NestorFooterComponent,
    NestorMobileHeaderComponent,
    NestorRichTextComponent,
    NestorExpandableRichTextComponent,
  ],
  exports: [
    NestorFooterComponent,
    NestorMobileHeaderComponent,
    NestorRichTextComponent,
    NestorExpandableRichTextComponent,
  ],
})
export class NestorCommonModule {}
