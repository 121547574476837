import { Component, inject } from '@angular/core';
import { posthogTrackPageviews } from '@frontend2/core';
import { FirebaseBloc, injectRouter } from '@frontend2/ui';
import { AuthBloc } from './core/auth.bloc';

@Component({
  selector: 'nestor-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class NestorAppComponent {
  constructor() {
    inject(FirebaseBloc).subscribeUserSignin(inject(AuthBloc).state$);
    posthogTrackPageviews(injectRouter());
  }
}
