import {
  LandingAssetType,
  LandingPagePb,
} from '@frontend2/proto/librarian/proto/landing_pages_pb';
import { integerToHex } from '../../utils';
import { isNil } from '../../utils/common.helpers';
import { isNotEmptyString } from '../../utils/strings.helpers';

export function isGhostLandingPb(page?: LandingPagePb): boolean {
  return isNil(page) || page.id === BigInt(0);
}

export function getLandingPbIllustrationUrl(landing: LandingPagePb): string {
  return (
    getLandingPbAssetUrl(landing, LandingAssetType.LANDING_ASSET_BACKGROUND) ??
    ''
  );
}

export function hasLandingPbIllustration(landing: LandingPagePb): boolean {
  return hasLandingPbAsset(landing, LandingAssetType.LANDING_ASSET_BACKGROUND);
}

function getLandingBrandLogoUrl(landing: LandingPagePb): string | undefined {
  return landing.brand?.bigLogoAssetUrl;
}

export function getLandingPbLogoUrl(landing: LandingPagePb): string {
  return (
    getLandingPbAssetUrl(landing, LandingAssetType.LANDING_ASSET_LOGO) ??
    getLandingBrandLogoUrl(landing) ??
    ''
  );
}

export function getLandingPbBrandName(landing: LandingPagePb): string {
  const brandName = landing.brand?.name;
  if (isNotEmptyString(brandName)) {
    return brandName;
  }
  return landing.brandName;
}

export function hasLandingPbLogo(landing: LandingPagePb): boolean {
  return hasLandingPbAsset(landing, LandingAssetType.LANDING_ASSET_LOGO);
}

function hasLandingPbAsset(
  landing: LandingPagePb,
  assetType: LandingAssetType,
): boolean {
  return landing.urls.some((u) => u.assetType === assetType);
}

function getLandingPbAssetUrl(
  landing: LandingPagePb,
  assetType: LandingAssetType,
): string | undefined {
  const urls = landing?.urls;
  const type = urls?.find((u) => u.assetType === assetType);
  if (type) {
    return type.url;
  }
  return;
}

export function getLandingCSSBackgroundColor(page: LandingPagePb): string {
  return integerToHex(Number(page.backgroundColor));
}
