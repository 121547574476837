export enum RequirementType {
  Unknown,
  InstagramInsights,
  PaymentDetails,
  ProductSeeding,
}

export interface Requirement {
  readonly requirement: RequirementType;
  readonly filled: boolean;
}

export function readableRequirement(value: RequirementType): string {
  switch (value) {
    case RequirementType.InstagramInsights:
      return $localize`Instagram insights`;
    case RequirementType.PaymentDetails:
      return $localize`Payment details`;
    case RequirementType.ProductSeeding:
      return $localize`Products & delivery address`;
    default:
      return $localize`Requested info`;
  }
}
