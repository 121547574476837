import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  input,
  output,
} from '@angular/core';

@Directive({
  selector: '[leftyLineClamp]',
  standalone: true,
})
export class LeftyLineClampDirective
  implements AfterViewInit, AfterViewChecked
{
  readonly elementRef: ElementRef<HTMLElement> = inject(
    ElementRef<HTMLElement>,
  );

  readonly leftyLineClamp = input.required<number>();

  readonly clamped$ = output<boolean>();

  getElementWidthInPx(container: HTMLElement): number {
    // reuse container to get font family and font size
    const node = container.cloneNode() as HTMLElement;

    // add a single character to measure the width
    node.innerHTML = '0';

    // add it to the DOM to render it
    // but we don't want it to be visible
    node.style.position = 'absolute';
    node.style.visibility = 'hidden';
    document.body.appendChild(node);

    const chWidth = node.clientWidth;
    node.remove();

    return container.clientWidth / chWidth;
  }

  ngAfterViewInit(): void {
    const lineClamp = this.leftyLineClamp();
    const container = this.elementRef.nativeElement;

    // -webkit-line-clamp is buggy on Safari
    // We need to fallback on a more hacky solution
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      const containerWidthInCh = this.getElementWidthInPx(container);
      const limit = containerWidthInCh * lineClamp;

      const innerText = container.innerHTML;
      if (innerText.length > limit) {
        container.innerHTML = `${innerText.substring(0, limit)}…`;
        this.clamped$.emit(true);
      } else {
        this.clamped$.emit(false);
      }
    } else {
      container.style.display = '-webkit-box';
      container.style.webkitLineClamp = `${lineClamp}`;
      container.style.webkitBoxOrient = 'vertical';
      container.style.overflow = 'hidden';
      container.style.textOverflow = 'ellipsis';
      container.style.whiteSpace = 'pre-line';
    }
  }

  ngAfterViewChecked(): void {
    const container = this.elementRef.nativeElement;
    this.clamped$.emit(container.clientHeight !== container.scrollHeight);
  }
}
