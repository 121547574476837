import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@frontend2/ui';
import { NestorCommonModule } from '../common.module';
import { NetworkLinkingComponent } from '../network-linking-component/network.linking.component';
import { LoginComponent } from './login-component/login.component';
import { ResetPasswordComponent } from './reset-password-component/reset.password.component';
import { SignUpComponent } from './signup-component/signup.component';

@NgModule({
  imports: [
    UiModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NestorCommonModule,
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    NetworkLinkingComponent,
  ],
  exports: [
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    NetworkLinkingComponent,
  ],
})
export class AuthComponentsModule {}
