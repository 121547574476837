<h1
  class="title"
  i18n
>
  Log in to Lefty
</h1>

<form
  #form="ngForm"
  (ngSubmit)="submit(form)"
>
  <div class="card-content">
    <lefty-feedback
      type="error"
      *ngIf="hasErrorMessage"
      [hideIcon]="true"
    >
      {{ errorMessage }}
    </lefty-feedback>
    <lefty-form-input
      i18n-label
      label="Email"
      i18n-placeholder
      placeholder="Email"
      autoFocus
      ngModel
      name="email"
      [email]="true"
      autocomplete="email"
      [required]="true"
    >
    </lefty-form-input>
    <lefty-form-input
      autocomplete="password"
      i18n-label
      label="Password"
      i18n-placeholder
      placeholder="password"
      i18n-helpText
      helpText="Forgot your password?"
      [helpLink]="resetPasswordRoute"
      ngModel
      name="password"
      type="password"
      [required]="true"
      [tooltip]="false"
    >
    </lefty-form-input>

    <button
      leftyButton="primary"
      size="large"
      class="action-btn"
      [disabled]="loading"
      [loading]="loading"
    >
      Log in
    </button>

    <span class="signup-msg body-1">
      <ng-container i18n>You do not have an account?</ng-container>&nbsp;
      <a [routerLink]="signUpRoute">Sign up</a>
    </span>
  </div>
</form>
