import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Toast } from './toast.models';
import { ToastManager } from './toast.service';
import { LeftyToastComponent } from './toast.component';
import { NgFor } from '@angular/common';

@Component({
  selector: 'lefty-toast-manager',
  styleUrls: ['toast-manager.component.scss'],
  template: `
    <lefty-toast
      *ngFor="let toast of manager.state(); trackBy: trackById"
      class="animated fadeInUp"
      (closeToast$)="manager.close(toast.id)"
      [toast]="toast"
    >
    </lefty-toast>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, LeftyToastComponent],
})
export class LeftyToastManagerComponent {
  constructor(public manager: ToastManager) {}

  trackById(index: number, toast: Toast): string {
    return toast.id;
  }
}
