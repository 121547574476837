import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { LoggedAuthorV2_AuthorNetworkInfo } from '@frontend2/proto/librarian/proto/registered_author_pb';
import { AuthorCreator } from './settings.models';

export function getEmptyAuthorCreator(): AuthorCreator {
  return {
    creator: new CreatorTiniestCard(),
    email: '',
    network: Network.NETWORK_UNKNOWN,
    networkUrl: '',
  };
}

export function creatorFromAuthorNetworkInfo(
  networkInfo: LoggedAuthorV2_AuthorNetworkInfo,
  email: string,
): AuthorCreator {
  return {
    creator: new CreatorTiniestCard({
      userName: networkInfo.userName,
      profilePicKept: networkInfo.profilePicture,
      followers: networkInfo.networkInfo?.count?.followersCount,
    }),
    email: email,
    network: networkInfo.networkInfo?.network ?? Network.NETWORK_UNKNOWN,
    networkUrl: networkInfo.networkInfo?.networkLink ?? '',
  };
}
