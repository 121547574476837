// PATHS
export const LANDING_PATH = 'landing';
export const COLLABORATIONS_PATH = 'collaborations';
export const LOGIN_PATH = 'login';
export const RESET_PASSWORD_PATH = 'reset_password';
export const LINK_NETWORK_PATH = 'link_network';
export const ONBOARDING_INSIGHTS_PATH = 'insights';
export const ONBOARDING_PAYMENT_PATH = 'payment';
export const ONBOARDING_PRODUCT_SEEDING_PATH = 'products';
export const ONBOARDING_DONE_PATH = 'done';
export const ONBOARDING_PATH = 'onboarding';
export const SIGNUP_PATH = 'signup';
export const CONVERSATIONS_PATH = 'conversations';

// ROUTES
export const LOGIN_ROUTE = `/${LOGIN_PATH}`;
export const SIGNUP_ROUTE = `/${SIGNUP_PATH}`;
export const RESET_PASSWORD_ROUTE = `/${RESET_PASSWORD_PATH}`;
export const LINK_NETWORK_ROUTE = `/${LINK_NETWORK_PATH}`;
export const COLLABORATIONS_ROUTE = `/${COLLABORATIONS_PATH}`;
export const SETTINGS_ROUTE = '/settings';
export const FACEBOOK_REDIRECT_PATH = 'facebook_redirect';
export const TIKTOK_REDIRECT_PATH = 'tiktok_redirect';
export const STRIPE_REDIRECT_PATH = 'stripe_redirect';
export const YOUTUBE_REDIRECT_PATH = 'youtube_redirect';
export const TWITTER_REDIRECT_PATH = 'twitter_redirect';
export const REQUIRE_PAYMENT_INFO_PATH = 'require_payment_info';
export const NOT_FOUND_PATH = '404';

export const LINK_NETWORK_ERROR_KEY = 'link_error';

// PARAMS
export const LANDING_ID_KEY = 'landing_id';
export const COLLABORATION_ENCRYPTED_ID_KEY = 'collaboration_id';
export const INSIGHTS_RESPONSE_QUERY_KEY = 'insights_response';
export const SHOW_WELCOME_DIALOG_KEY = 'wd';

export const COLLAB_STATUS = 'collab_status';

// URLS
export const TIKTOK_OAUTH_URL = 'https://www.tiktok.com/v2/auth/authorize/';
export const FACEBOOK_OAUTH_URL = 'https://www.facebook.com/v19.0/dialog/oauth';
export const YOUTUBE_OAUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
export const FACEBOOK_GRAPH_API_BASE_URL = 'https://graph.facebook.com';
export const INSTAGRAM_BUSINESS_HELPER_URL =
  'https://help.instagram.com/2358103564437429';
export const TWITTER_OAUTH_URL = 'https://twitter.com/i/oauth2/authorize';

export const NESTOR_APP_TITLE = 'Lefty Creator';

export function buildPageTitle(...title: string[]): string {
  return `${title.join(' - ')} | ${NESTOR_APP_TITLE}`;
}
