import { Injectable } from '@angular/core';
import Talk from 'talkjs';
import { LoggedAuthorV2_ChatInfo } from '@frontend2/proto/librarian/proto/registered_author_pb';
import { BlocState, isNotNil } from '@frontend2/core';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftyChatBloc extends BlocState<Talk.Session | undefined> {
  constructor() {
    super(undefined);
  }

  async startSession(
    appId: string,
    userId: string,
    userName: string,
    userSignature: string,
  ): Promise<Talk.Session | undefined> {
    await Talk.ready;
    const userOptions: Talk.UserOptions = {
      id: userId,
      name: userName,
      role: 'influencer',
    };

    const user = new Talk.User(userOptions);

    const sessionOptions: Talk.SessionOptions = {
      appId: appId,
      me: user,
      signature: userSignature,
    };
    return this.updateState(new Talk.Session(sessionOptions));
  }

  async startSessionFromAuth(
    username: string,
    chatInfo: LoggedAuthorV2_ChatInfo,
  ): Promise<Talk.Session | undefined> {
    return await this.startSession(
      chatInfo.appId,
      chatInfo.authorChatId,
      username,
      chatInfo.authorChatSignature,
    );
  }

  async waitReady(): Promise<Talk.Session> {
    const session = await firstValueFrom(
      this.state$.pipe(first((element) => isNotNil(element))),
    );
    if (isNotNil(session)) {
      return session;
    } else {
      throw new Error('No session found.');
    }
  }
}
