import { Code, ConnectError } from '@connectrpc/connect';
import { BroncoTaskError } from '@frontend2/api';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';

const _alreadyExistsNetwork = $localize`Sorry the Instagram account you entered is already used by someone else. If you think this is a mistake please contact us at contact@lefty.io or via the chat.`;
const _accountNotFound = $localize`Sorry the Instagram account you entered does not exist or is private. Please try again with another account.`;
/// Function that handle error coming from signInWithInstagram API
/// it might be Librarian Error or a Bronco error
export function signInWithInstagramErrorMsg(error: unknown): string {
  if (error instanceof ConnectError) {
    console.error(error.code, error.message);
    if (error.code === Code.AlreadyExists) {
      return _alreadyExistsNetwork;
    }
    return _accountNotFound;
  }

  if (error instanceof BroncoTaskError) {
    console.error(error.task.status, error.message);
    return _accountNotFound;
  }

  console.error(error);
  return $localize`Failed to link Instagram account`;
}

/// Function that handle error coming from confirmSignInWithInstagram API
export function confirmSignInWithInstaErrorMsg(
  influencer: CreatorTiniestCard,
  error: unknown,
): string {
  if (error instanceof ConnectError) {
    console.error(error.code, error.message);
    if (error.code === Code.AlreadyExists) {
      return _alreadyExistsNetwork;
    }

    return _accountNotFound;
  }

  return $localize`Failed to link ${influencer.userName} account`;
}
