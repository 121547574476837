import {
  MapStringString,
  RouteParams,
  getEnumName,
  isEmptyObject,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { OnboardingStep } from '../onboarding.models';
import { InstagramOAuthService } from './instagram.service';
import { TikTokOAuthService } from './tiktok.service';
import { TwitterOAuthService } from './twitter.service';
import { YoutubeOAuthService } from './youtube.service';

export interface NetworkOAuthService {
  buildUrl(redirectUri: URL, args?: { state?: OauthState }): URL;
  parseCodeFromQueryParams(queryParams: MapStringString): string;
  getRedirectURL(): URL;
}

export function getNetworkOAuthService(network: Network): NetworkOAuthService {
  switch (network) {
    case Network.TIK_TOK:
      return new TikTokOAuthService();
    case Network.INSTA:
      return new InstagramOAuthService();
    case Network.YOUTUBE:
      return new YoutubeOAuthService();
    case Network.TWEET:
      return new TwitterOAuthService();
    default:
      throw new Error(
        `Oauth not implemented for ${getEnumName(Network, network)}`,
      );
  }
}

export interface OauthState {
  readonly network?: Network;
  readonly landingEncryptedId?: string;
  readonly onboardingStep?: OnboardingStep;
  readonly csrf?: string;
}

const OAUTH_STATE_KEY = 'state';

export function parseOAuthState(params: RouteParams): OauthState {
  try {
    return JSON.parse(params.queryParameters[OAUTH_STATE_KEY]);
  } catch (e) {
    return {};
  }
}

export function applyOAuthStateToUrl(url: URL, state: OauthState): URL {
  if (isEmptyObject(state)) {
    url;
  }

  url = new URL(url);

  url.searchParams.set(OAUTH_STATE_KEY, JSON.stringify(state));

  return url;
}
