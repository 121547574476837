import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeftyComponent } from '@frontend2/ui';
import { AuthBloc } from '../../core/auth.bloc';
import {
  LINK_NETWORK_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
} from '../../core/utils';
import { buildHomeRedirectionRouteUrl } from '../redirect.helpers';

@Component({
  template: '',
})
export abstract class AuthBaseComponent
  extends LeftyComponent
  implements OnInit
{
  constructor(
    authBloc: AuthBloc,
    protected _router: Router,
  ) {
    super();
    this.authBloc = authBloc;
    this._router = _router;
  }

  authBloc: AuthBloc;

  routeUrlBase = '';

  @Input()
  landingId = '';

  get signUpRoute(): string {
    return this.routeUrlBase + SIGNUP_ROUTE;
  }

  get loginRoute(): string {
    return this.routeUrlBase + LOGIN_ROUTE;
  }

  get resetPasswordRoute(): string {
    return this.routeUrlBase + RESET_PASSWORD_ROUTE;
  }

  get linkingRoute(): string {
    return this.routeUrlBase + LINK_NETWORK_ROUTE;
  }

  // in this case we have two different types of routes (/landing/abcd/login and /login)
  // so we need to have the complete parent path
  // we get the url and we take off the child route to get a routeUrlBase
  ngOnInit(): void {
    const _url = this._router.url.split('/');
    _url.splice(_url.length - 1, 1);
    this.routeUrlBase = _url.join('/');
  }

  // using a getter of the route because we are using a router link in reset password redirection (after password reset success)
  get homeRedirectionRoute(): string {
    return buildHomeRedirectionRouteUrl(this.landingId);
  }
}
