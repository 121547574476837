import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  LoggedAuthorV2,
  LoggedAuthorV2_AuthorNetworkInfo,
} from '@frontend2/proto/librarian/proto/registered_author_pb';

export function getAuthorNetworkInfo(
  user: LoggedAuthorV2,
  network: Network,
): LoggedAuthorV2_AuthorNetworkInfo {
  return (
    user.authors.find((e) => e.networkInfo?.network === network) ??
    new LoggedAuthorV2_AuthorNetworkInfo()
  );
}

export function hasLinkedNetwork(
  user: LoggedAuthorV2,
  network: Network,
): boolean {
  return user.authors.some((n) => n.networkInfo?.network === network);
}
