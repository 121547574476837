import { MapStringString } from '@frontend2/core';
import {
  NetworkOAuthService,
  OauthState,
  applyOAuthStateToUrl,
} from './network-oauth.service';
import { environment } from '../../../environments/environment';
import { TWITTER_OAUTH_URL, TWITTER_REDIRECT_PATH } from '../../core/utils';

const TWITTER_OAUTH_CODE_KEY = 'code';
interface OAuthUrlRequest extends Record<string, string> {
  readonly client_id: string;
  readonly redirect_uri: string;
  readonly scope: string;
  readonly response_type: string;
  readonly code_challenge: string;
  readonly code_challenge_method: string;
  readonly authorization_grant_type: string;
}

export class TwitterOAuthService implements NetworkOAuthService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildUrl(redirectUri: URL, args?: { state?: OauthState }): URL {
    const url = new URL(TWITTER_OAUTH_URL);
    const csrfState =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    const request: OAuthUrlRequest = {
      client_id: environment.twitter.clientId,
      redirect_uri: redirectUri.toString(),
      scope: environment.twitter.scope,
      response_type: 'code',
      code_challenge: 'challenge',
      code_challenge_method: 'plain',
      authorization_grant_type: 'authorization_code',
    };
    url.search = new URLSearchParams(request).toString();

    let state = args?.state ?? {};
    state = { ...state, csrf: csrfState };

    return applyOAuthStateToUrl(url, state);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseCodeFromQueryParams(queryParams: MapStringString): string {
    return queryParams[TWITTER_OAUTH_CODE_KEY] ?? '';
  }

  getRedirectURL(): URL {
    return new URL(window.location.origin + '/' + TWITTER_REDIRECT_PATH);
  }
}
