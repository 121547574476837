import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import {
  InitGetProductsListsResponse,
  ProductPb,
  ProductState,
  ProductVariantAttributePb,
  ProductVariantPb,
  ProductWithInfoPb,
} from '@frontend2/proto/librarian/proto/products_pb';
import { LeftyFormat } from '../../format';
import { orderBy } from '../../utils';
import {
  isEmptyArray,
  isNil,
  isNotNil,
  plural,
} from '../../utils/common.helpers';

export function productStateToReadable(state: ProductState): string {
  switch (state) {
    case ProductState.ARCHIVED:
      return 'Archived';
    case ProductState.DELETED:
      return 'Deleted';
    case ProductState.LIVE:
      return 'Active';
    case ProductState.STATE_UNSPECIFIED:
      return 'Unknown';
    default:
      return 'Unknown';
  }
}

export function isGhostProductWithInfoPb(product: ProductWithInfoPb): boolean {
  if (isNil(product.product) || product.product.id === BigInt(0)) {
    return true;
  }
  return false;
}

export function isGhostProductPb(product: ProductPb): boolean {
  return product.id === BigInt(0);
}

export function getProductWithInfoStoreName(
  product: ProductWithInfoPb,
): string {
  const store = product.store;
  return store?.store.value?.name ?? '';
}

export function getProductWithInfoStoreDomain(
  product: ProductWithInfoPb,
): string {
  const store = product.store;
  switch (store?.store.case) {
    case 'manualStore':
    case 'shopifyStore':
    case 'salesforceStore':
      return store.store.value.domain ?? '';
    default:
      return '';
  }
}

export function buildVariantsSummaryTextFromProductWithInfo(
  product: ProductWithInfoPb,
): string {
  const labels = getVarriantsAttributesMap(product);

  if (labels.size === 0) {
    return '-';
  }
  const result: string[] = [];
  labels.forEach((val, key) =>
    result.push(
      val.length +
        ' ' +
        key.toLowerCase() +
        plural(val.length, { one: '', other: 's' }),
    ),
  );

  return result.join(', ');
}

//Map<(variant name) , (possible values)>
export function getVarriantsAttributesMap(
  product: ProductWithInfoPb,
): Map<string, string[]> {
  const labels = new Map<string, string[]>();

  if (isNotNil(product.product)) {
    const variants = product.product.variants.filter(
      (v) => v.attributes.length > 0,
    );

    for (const variant of variants) {
      for (const attr of variant.attributes) {
        const currentVal = labels.get(attr.name) ?? [];
        if (!currentVal?.includes(attr.value)) {
          labels.set(attr.name, [...currentVal, attr.value]);
        }
      }
    }
  }

  return labels;
}

export function buildProductPriceRangeText(info: ProductWithInfoPb): string {
  const currency = getProductStoreCurrency(info);

  if (currency === CurrencyEnum.NA) {
    return '-';
  }

  const [min, max] = [info.minPrice, info.maxPrice];

  if (isNotNil(min) && isNotNil(max)) {
    if (min === max) {
      return LeftyFormat.currency(min, { currency: currency, showZero: true });
    }
    const minFormatted = LeftyFormat.currency(min, {
      currency: currency,
      showZero: true,
    });
    const maxFormatted = LeftyFormat.currency(max, {
      currency: currency,
      showZero: true,
    });

    return $localize`${minFormatted} to ${maxFormatted}`;
  }

  const price = min ?? max;
  if (isNotNil(price)) {
    return LeftyFormat.currency(price ?? 0, {
      currency: currency,
      showZero: true,
    });
  }

  return '-';
}

export function getProductStoreCurrency(
  product: ProductWithInfoPb,
): CurrencyEnum {
  const store = product.store;
  switch (store?.store.case) {
    case 'manualStore':
    case 'shopifyStore':
    case 'salesforceStore':
      return store.store.value.currency;
    default:
      return CurrencyEnum.NA;
  }
}
export function getVariantFormattedCurrency(
  product: ProductWithInfoPb,
  variant?: ProductVariantPb,
  showZero?: boolean,
): string {
  const currency = getProductStoreCurrency(product);
  if (isNotNil(variant) && variant.attributes.length > 0) {
    return LeftyFormat.currency(variant.price ?? 0, {
      currency: currency,
      showZero: showZero,
    });
  } else {
    return buildProductPriceRangeText(product);
  }
}

export function areVariantAttributesEqual(
  a: ProductVariantAttributePb[],
  b: ProductVariantAttributePb[],
): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const sortedAttributesA = orderBy(a, (item) => item.name);
  const sortedAttributesB = orderBy(b, (item) => item.name);

  for (let i = 0; i < sortedAttributesA.length; i++) {
    if (!sortedAttributesA[i].equals(sortedAttributesB[i])) {
      return false;
    }
  }

  return true;
}

export function productWithInfoHasVariant(product: ProductWithInfoPb): boolean {
  if (isNil(product.product)) {
    return false;
  }
  return productHasVariant(product.product);
}

export function productHasVariant(product: ProductPb): boolean {
  return product.variants.filter((v) => v.attributes.length > 0).length > 0;
}

export function productHasAvailableQuantity(
  product: ProductWithInfoPb,
  minStockRequired = 1,
): boolean {
  if (productWithInfoHasVariant(product)) {
    return (
      isNotNil(product.product) &&
      product.product.variants.some((v) =>
        variantHasAvailableQuantity(v, minStockRequired),
      )
    );
  } else {
    return product.available >= minStockRequired;
  }
}

export function variantHasAvailableQuantity(
  variant: ProductVariantPb,
  minStockRequired = 1,
): boolean {
  return variant.quantity >= minStockRequired;
}

export function getProductVariantName(variant: ProductVariantPb): string {
  return variant.attributes.map((a) => a.value).join(' / ');
}

export function getProductNameWithVariant(
  productVariant: ProductVariantPb,
): string {
  if (productVariant.attributes.length > 0) {
    return `${productVariant.productName} (${getProductVariantName(productVariant)})`;
  } else {
    return productVariant.productName;
  }
}

export function isGhostInitGetProductsListsResponse(
  val: InitGetProductsListsResponse,
): boolean {
  return isEmptyArray(val.stores);
}

// when selecting variants for influencers
// (product selected quantity * selected influencers count)
//  must be less than or equal to total quantity of the product
export function getVariantMaxAvailableForInfluencerSelection(
  variant: ProductVariantPb,
  influencerCount: number,
): number {
  const quantity = variant.quantity ?? 0;
  if (influencerCount === 0) {
    return quantity;
  }
  return Math.floor(quantity / influencerCount);
}
