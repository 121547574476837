import { Injectable, inject } from '@angular/core';
import { LeftyEvents, LeftyEventsBus } from '@frontend2/ui';

type NestorEvents = LeftyEvents & {
  accept_invitation: bigint;
  decline_invitation: bigint;
};

@Injectable({ providedIn: 'root' })
export class NestorEventsBus extends LeftyEventsBus<NestorEvents> {}

export function injectNestorEventBus(): NestorEventsBus {
  return inject(NestorEventsBus);
}
