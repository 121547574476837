<div class="header">
  <h1
    class="title"
    i18n
  >
    Reset your password
  </h1>

  <!-- displayed on get reset link step only (first step) -->

  <span
    *ngIf="!showPasswordSuccess && !showEmailSuccess && !hasValidToken"
    class="signin-msg body-1"
  >
    <ng-container i18n>You do not have an account?</ng-container>&nbsp;
    <a [routerLink]="signUpRoute"> {{ Messages.signUp }} </a>
  </span>
</div>

<!-- form that will be handling the two cases
      1 - get reset link
      2 - set new password and confirm it
 -->

<div class="content">
  <lefty-feedback
    type="error"
    *ngIf="hasErrorMessage"
    [hideIcon]="true"
  >
    {{ errorMessage }}
  </lefty-feedback>

  <!-- START: EMAIL FORM-->
  <ng-container *ngIf="!showPasswordSuccess && !showEmailSuccess">
    <form
      #form="ngForm"
      (ngSubmit)="submit(form)"
    >
      <lefty-form-input
        *ngIf="!hasValidToken"
        i18n-label
        label="Email"
        autoFocus
        ngModel
        type="email"
        name="email"
        autocomplete="email"
        i18n-placeholder
        placeholder="Email"
        [email]="true"
        [required]="true"
      >
      </lefty-form-input>
      <lefty-form-input
        *ngIf="hasValidToken"
        i18n-label
        label="New Password"
        ngModel
        name="new-password"
        type="password"
        minlength="8"
        [required]="true"
        [tooltip]="false"
      >
      </lefty-form-input>
      <lefty-form-input
        *ngIf="hasValidToken"
        i18n-label
        label="Confirm Password"
        ngModel
        name="confirm-password"
        type="password"
        [required]="true"
        [tooltip]="false"
      >
      </lefty-form-input>

      <input
        hidden
        type="submit"
      />
    </form>

    <div class="actions">
      <button
        class="action-btn large primary"
        (click)="submit(form)"
        [disabled]="loading"
      >
        <lefty-spinner *ngIf="loading"></lefty-spinner>
        {{ submitMsg }}
      </button>

      <a
        class="return-btn"
        [routerLink]="loginRoute"
        i18n
      >
        Return to login
      </a>
    </div>
  </ng-container>
  <!-- END: EMAIL FORM-->

  <!-- START: email send with success message -->
  <p
    i18n
    *ngIf="showEmailSuccess"
  >
    Go to your inbox and follow the link
  </p>
  <!-- END: email send with success message -->

  <!-- START: password reset with success message -->
  <ng-container *ngIf="showPasswordSuccess">
    <p i18n>You password has been reset !</p>

    <div class="actions">
      <a
        class="button primary large"
        [routerLink]="homeRoute"
        i18n
      >
        Continue to Lefty
      </a>
    </div>
  </ng-container>
  <!-- END: password reset with success message -->
</div>
