<h1
  class="title"
  i18n
>
  Sign up to Lefty
</h1>

<form
  #form="ngForm"
  (ngSubmit)="submit(form)"
>
  <div class="card-content">
    <lefty-feedback
      type="error"
      *ngIf="hasErrorMessage"
      [hideIcon]="true"
    >
      {{ errorMessage }}
    </lefty-feedback>
    <lefty-form-input
      i18n-label
      label="Email"
      i18n-placeholder
      placeholder="Email"
      autoFocus
      ngModel
      type="email"
      name="email"
      [email]="true"
      autocomplete="email"
      [required]="true"
    >
    </lefty-form-input>
    <lefty-form-input
      autocomplete="password"
      i18n-label
      label="Password"
      i18n-placeholder
      placeholder="password"
      ngModel
      name="password"
      type="password"
      minlength="8"
      [required]="true"
      [tooltip]="false"
    >
    </lefty-form-input>

    <div
      class="terms"
      (click)="acceptTerms = !acceptTerms"
    >
      <lefty-checkbox [checked]="acceptTerms"></lefty-checkbox>

      <div
        class="body-1"
        i18n
      >
        I have read and agree to the
        <a
          class="black underline"
          [attr.href]="privacyPolicyUrl"
          target="_blank"
          >Lefty Terms of Service.
        </a>
      </div>
    </div>

    <button
      leftyButton="primary"
      size="large"
      class="action-btn"
      [disabled]="loading"
      [loading]="loading"
      i18n
    >
      Sign up
    </button>

    <span class="signin-msg body-1">
      <ng-container i18n>Already have an account ?</ng-container>&nbsp;
      <a
        [routerLink]="loginRoute"
        i18n
        >Log in</a
      >
    </span>
  </div>
</form>
<p
  class="about small"
  i18n
>
  Lefty is the Influencer Marketing platform used by major brands and agencies
  to manage their Influencer Marketing programs.
  <a
    target="_blank"
    href="https://lefty.io"
    >More about Lefty</a
  >
</p>
