import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { Database, onValue, ref } from '@angular/fire/database';
import {
  collection,
  CollectionReference,
  Firestore,
} from '@angular/fire/firestore';
import { BlocState, Promises } from '@frontend2/core';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { LoggedAuthorV2 } from '@frontend2/proto/librarian/proto/registered_author_pb';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';

export enum FirebaseConnectionState {
  unkown,
  notConnected,
  connected,
}

export interface FirebaseState {
  readonly connection: FirebaseConnectionState;
  readonly isLogged: boolean;
}

/**
 * Bloc that handle the connection and authentification to Firebase
 *
 * It uses custom token passed in is_logged request
 */
@Injectable({ providedIn: 'root' })
export class FirebaseBloc extends BlocState<FirebaseState> {
  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private database: Database,
  ) {
    super({
      connection: FirebaseConnectionState.unkown,
      isLogged: false,
    });
  }

  subscribeUserSignin(
    user$: Observable<LoggedAuthorV2 | LoggedBootstrapping>,
  ): void {
    user$
      .pipe(
        filter((u) => (u instanceof LoggedAuthorV2 ? u.logged : u.isLogged)),
        map((u) => u.firebaseToken),
        distinctUntilChanged(),
      )
      .subscribe((token) => this._signInWithToken(token));
  }

  private async _signInWithToken(token: string): Promise<void> {
    try {
      // if Firebase is too slow to connect
      // we want it to fail
      // it's probably because behind China proxy
      await Promises.timeout(signInWithCustomToken(this.auth, token), 3000);

      this.updateState({
        connection: FirebaseConnectionState.connected,
        isLogged: true,
      });

      this._listenConnectionState();
    } catch (e) {
      console.warn('Failed to signIn', e);
      this.updateState({
        connection: FirebaseConnectionState.notConnected,
        isLogged: false,
      });
    }
  }

  private _listenConnectionState(): void {
    // https://firebase.google.com/docs/database/web/offline-capabilities#section-connection-state
    const connectedRef = ref(this.database, '.info/connected');
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        this.updateState({
          ...this.currentState,
          connection: FirebaseConnectionState.connected,
        });
      } else {
        this.updateState({
          ...this.currentState,
          connection: FirebaseConnectionState.notConnected,
        });
      }
    });
  }

  getCollection(name: string): CollectionReference {
    return collection(this.firestore, name);
  }
}
