import { Inject, Injectable } from '@angular/core';
import { StringValue } from '@bufbuild/protobuf';
import {
  RegisteredAuthorClient,
  RegisteredAuthorClientProvider,
} from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { LinkNetworkRequest } from '@frontend2/proto/librarian/proto/registered_author_pb';
import { ResolveUserNameRespV2 } from '@frontend2/proto/librarian/proto/users_pb';
import { BroncoService } from '@frontend2/ui';
import { AuthBloc } from '../../core/auth.bloc';

@Injectable({ providedIn: 'root' })
export class NetworkLinkingService {
  constructor(
    @Inject(RegisteredAuthorClientProvider)
    private registeredAuthor: RegisteredAuthorClient,
    private broncoService: BroncoService,
    private auth: AuthBloc,
  ) {}

  async link(code: string, network: Network): Promise<StringValue> {
    const token = await this.registeredAuthor.linkNetwork(
      new LinkNetworkRequest({
        code,
        network: network,
      }),
    );

    if (isNotEmptyString(token.value)) {
      await this.broncoService.subscribeWithoutToast(token.value).wait();
      await this.auth.checkIsLogged();
    }

    return token;
  }

  // input can be instagram url ou username
  async addUserNetwork(input: string): Promise<CreatorTiniestCard> {
    const resolveResponse: ResolveUserNameRespV2 =
      await this.registeredAuthor.signInWithInstagram(
        new StringValue({ value: input }),
      );
    let userId: string | undefined;
    if (resolveResponse.response.case === 'taskToken') {
      userId = await this.getUserId(resolveResponse.response.value);
    } else {
      userId = resolveResponse.response.value;
    }
    return this.getInstagramProfileInfo(userId);
  }

  async getUserId(token: string): Promise<string> {
    const task = await this.broncoService.subscribeWithoutToast(token).wait();
    let userId;
    try {
      if (!task.progress_metadata) {
        return '';
      }
      userId = JSON.parse(task.progress_metadata)['id'];
    } catch (e) {
      return '';
    }
    return userId;
  }

  async getInstagramProfileInfo(
    id: string | undefined,
  ): Promise<CreatorTiniestCard> {
    if (!isNotEmptyString(id)) {
      // this can happen if bronco does not return us a userId after resolving
      // we want to throw an error here to stop the process and avoid sending empty param to the backend
      throw Error('we could not fetch network information');
    }
    return await this.registeredAuthor.getInstagramProfileInfo(
      new StringValue({ value: id }),
    );
  }

  async confirmProfile(influencer: CreatorTiniestCard): Promise<void> {
    await this.registeredAuthor.confirmSignInWithInstagram(
      new StringValue({ value: influencer.userId }),
    );
  }
}
