import { isNotEmptyString } from '@frontend2/core';
import { COLLABORATIONS_ROUTE } from '../core/utils';

export function buildHomeRedirectionRouteUrl(landingId: string): string {
  // if we login from a landing invite link, we redirect to the invitation details, else we redirect to the main route (collab list)
  if (isNotEmptyString(landingId)) {
    return COLLABORATIONS_ROUTE + '/' + landingId;
  } else {
    return '/';
  }
}
