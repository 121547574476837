import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Output } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Code, ConnectError } from '@connectrpc/connect';
import { Messages, isNotEmptyString } from '@frontend2/core';
import { LoggedAuthorV2 } from '@frontend2/proto/librarian/proto/registered_author_pb';
import {
  LeftyButtonDirective,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
} from '@frontend2/ui';
import { captureException } from '@sentry/browser';
import { Subject } from 'rxjs';
import { AuthBloc } from '../../../core/auth.bloc';
import { AuthBaseComponent } from '../auth.helper';

@Component({
  selector: 'login-component',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    LeftyFeedbackComponent,
    LeftyFormInputComponent,
    LeftyButtonDirective,
    RouterLink,
  ],
})
export class LoginComponent extends AuthBaseComponent {
  constructor(_authBloc: AuthBloc, _router: Router) {
    super(_authBloc, _router);
    this.disposer.addSubject(this.loginSuccess$);
  }

  @Output()
  readonly loginSuccess$ = new Subject<LoggedAuthorV2>();

  errorMessage = '';

  get hasErrorMessage(): boolean {
    return isNotEmptyString(this.errorMessage);
  }

  loading = false;

  acceptTerms = false;

  async submit(form: NgForm): Promise<void> {
    if (form.invalid) {
      return;
    }

    this.setState(() => {
      this.errorMessage = '';
      this.loading = true;
    });

    try {
      await this.authBloc.login(
        form.controls['email'].value.trim(),
        form.controls['password'].value.trim(),
      );

      const author = await this.authBloc.checkIsLogged();
      this.loginSuccess$.next(author);
    } catch (e) {
      this._handleSigninError(e);
    } finally {
      this.setState(() => (this.loading = false));
    }
  }

  private isAuthError(error: unknown): boolean {
    return (
      error instanceof ConnectError &&
      (error.code === Code.Unauthenticated ||
        error.code === Code.NotFound ||
        error.code === Code.PermissionDenied)
    );
  }

  private _handleSigninError(error: unknown): void {
    console.error(error);
    if (this.isAuthError(error)) {
      this.setState(() => (this.errorMessage = this._loginErrorMsg));
    } else {
      captureException(error);
      this.setState(() => (this.errorMessage = Messages.errorHappen));
    }
  }

  private get _loginErrorMsg(): string {
    return $localize`Login failed, email or password incorrect`;
  }
}
