import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

export function initSentry(
  environmentName: string,
  dsn: string,
  options?: {
    isProduction?: boolean;
    enablePosthog?: boolean;
    release?: string;
  },
): void {
  const integrations = [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ];

  if (options?.enablePosthog) {
    // FIXME: https://github.com/PostHog/posthog-js/issues/1205
    // const dsnSegments = dsn.split('/');
    // const projectId = Number(dsnSegments[dsnSegments.length - 1]);
    // integrations.push(
    //   new posthog.SentryIntegration(
    //     posthog,
    //     'lefty',
    //     projectId,
    //     'https://sentry.lefty.io/organizations/',
    //   ),
    // );
  }

  Sentry.init({
    dsn,
    environment: environmentName,
    maxBreadcrumbs: 50,
    release: options?.release,
    integrations,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: options?.isProduction ? 0.2 : 1,

    tracePropagationTargets: [
      'https://api-staging.lefty.io',
      'https://api.lefty.io',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

@NgModule({
  imports: [Sentry.TraceModule],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class SentryModule {
  // force instantiate Tracing
  constructor(private trace: Sentry.TraceService) {}
}
