import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { isNotEmptyString } from '@frontend2/core';
import {
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  LeftySpinnerComponent,
} from '@frontend2/ui';
import { AuthBloc } from '../../../core/auth.bloc';
import { AuthBaseComponent } from '../auth.helper';

@Component({
  selector: 'reset-password-component',
  templateUrl: 'reset.password.component.html',
  styleUrls: ['reset.password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    LeftyFeedbackComponent,
    FormsModule,
    LeftyFormInputComponent,
    LeftySpinnerComponent,
  ],
})
export class ResetPasswordComponent extends AuthBaseComponent {
  constructor(
    private _authBloc: AuthBloc,
    _router: Router,
  ) {
    super(_authBloc, _router);
  }

  @Input()
  errorMessage = '';

  showEmailSuccess = false;

  showPasswordSuccess = false;

  loading = false;

  @Input()
  hasValidToken = false;

  @Input()
  token = '';

  get hasErrorMessage(): boolean {
    return isNotEmptyString(this.errorMessage);
  }

  private _showError(message: string): void {
    this.setState(() => (this.errorMessage = message));
  }

  get submitMsg(): string {
    if (this.hasValidToken) {
      return $localize`Reset password`;
    }
    return $localize`Get reset link`;
  }

  // the submit method is the same for the send reset link and reset password
  // we only need to check if we have a valid token to know which process we will handle
  async submit(form: NgForm): Promise<void> {
    if (form.invalid) {
      return;
    }

    this.setState(() => (this.loading = true));
    try {
      if (!this.hasValidToken) {
        await this._sendResetPassword(form);
      } else {
        await this._resetPassword(form);
      }
    } catch (e) {
      console.error(e);
      this._showError($localize`Failed to reset password`);
    } finally {
      this.setState(() => (this.loading = false));
    }
  }

  private async _sendResetPassword(form: NgForm): Promise<void> {
    await this._authBloc.sendResetPasswordLinkAPI(
      form.controls['email'].value.trim(),
    );
    this.setState(() => (this.showEmailSuccess = true));
  }

  private async _resetPassword(form: NgForm): Promise<void> {
    const newPassword = form.controls['new-password'].value.trim();
    const confirmPassword = form.controls['confirm-password'].value.trim();
    if (newPassword !== confirmPassword) {
      this._showError($localize`Confimation doesn't match the password`);
    } else {
      await this._authBloc.resetPasswordAPI(this.token, newPassword);
      this.setState(() => (this.showPasswordSuccess = true));
    }
  }

  get homeRoute(): string {
    return this.homeRedirectionRoute;
  }
}
