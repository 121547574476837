import { MapStringString } from '@frontend2/core';
import { environment } from '../../../environments/environment';
import { YOUTUBE_OAUTH_URL, YOUTUBE_REDIRECT_PATH } from '../../core/utils';
import {
  NetworkOAuthService,
  OauthState,
  applyOAuthStateToUrl,
} from './network-oauth.service';

const YOUTUBE_OAUTH_CODE_KEY = 'code';

interface OAuthUrlRequest extends Record<string, string> {
  readonly client_id: string;
  readonly redirect_uri: string;
  readonly scope: string;
  readonly response_type: string;
  readonly access_type: string;
  readonly include_granted_scopes: string;
  readonly prompt: string;
}

export class YoutubeOAuthService implements NetworkOAuthService {
  buildUrl(redirectUri: URL, args?: { state?: OauthState }): URL {
    const csrfState =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const url = new URL(YOUTUBE_OAUTH_URL);

    const request: OAuthUrlRequest = {
      client_id: environment.youtube.clientId,
      redirect_uri: redirectUri.toString(),
      scope: environment.youtube.scope,
      response_type: 'code',
      access_type: 'offline',
      include_granted_scopes: 'true',
      prompt: 'consent',
    };

    url.search = new URLSearchParams(request).toString();

    let state = args?.state ?? {};
    state = { ...state, csrf: csrfState };

    return applyOAuthStateToUrl(url, state);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseCodeFromQueryParams(queryParams: MapStringString): string {
    return queryParams[YOUTUBE_OAUTH_CODE_KEY] ?? '';
  }

  getRedirectURL(): URL {
    return new URL(window.location.origin + '/' + YOUTUBE_REDIRECT_PATH);
  }
}
