import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import {
  LeftyFormat,
  Messages,
  Networks,
  capitalize,
  isNotEmptyString,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import {
  LeftyButtonDirective,
  LeftyComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  NetworkIconComponent,
  ProfilePictureComponent,
} from '@frontend2/ui';
import { Subject } from 'rxjs';
import {
  confirmSignInWithInstaErrorMsg,
  signInWithInstagramErrorMsg,
} from '../errors.helpers';
import { getNetworkOAuthService } from '../network-oauth/network-oauth.service';
import { OnboardingStep } from '../onboarding.models';
import { NetworkLinkingService } from './network.linking.service';

@Component({
  selector: 'network-linking-component',
  templateUrl: 'network.linking.component.html',
  styleUrls: ['network.linking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyFeedbackComponent,
    NgFor,
    NetworkIconComponent,
    FormsModule,
    LeftyFormInputComponent,
    LeftyButtonDirective,
    ProfilePictureComponent,
  ],
})
export class NetworkLinkingComponent extends LeftyComponent {
  constructor(private _service: NetworkLinkingService) {
    super();
    this.disposer.add(this.success$);
  }

  loading = false;

  @Input()
  errorMessage = '';

  influencer = new CreatorTiniestCard();

  @Input()
  landingId = '';

  @Input()
  supportedNetworks: Network[] = [];

  @Input()
  secondStepNetworks: Network[] = [];

  @Output()
  readonly success$ = new Subject<void>();

  selectedNetwork: Network = Network.NETWORK_UNKNOWN;

  private _currentStep: NetworkLinkingStep = NetworkLinkingStep.SignInStep;

  get hasErrorMessage(): boolean {
    return isNotEmptyString(this.errorMessage);
  }

  title(network: Network): string {
    switch (this._currentStep) {
      case NetworkLinkingStep.LinkingStep:
        return $localize`Indicate what is the ${Networks.readable(
          network,
        )} account that will be linked to your profile.`;
      case NetworkLinkingStep.ConfirmationStep:
        return $localize`Good! We have identified you. Please confirm this is your profile.`;
      default:
        return $localize`Great! Now identify one of your networks to finalize the creation of your account`;
    }
  }

  submittingMsg(loading: boolean): string {
    if (loading) {
      return $localize`Verifiyng profile`;
    }
    return $localize`Submit`;
  }

  signInWithMessage(network: Network): string {
    return $localize`Sign in with ${Networks.readable(network)}`;
  }

  userNetworkInput(network: Network): string {
    return $localize`${Networks.readable(network)} profile name or URL`;
  }

  get followers(): string {
    const count = LeftyFormat.followers(this.influencer?.followers ?? 0, {
      compact: true,
    });
    const label = capitalize(
      Messages.followersLabel(this.influencer?.followers ?? 0),
    );

    return count + ' ' + label;
  }

  signInWith(network: Network): void {
    if (this.secondStepNetworks.includes(network)) {
      this.setState(() => {
        this.selectedNetwork = network;
        this._currentStep = NetworkLinkingStep.LinkingStep;
      });
    } else {
      const oauthService = getNetworkOAuthService(network);
      const redirectURL = oauthService.getRedirectURL();
      const step = isNotEmptyString(this.landingId)
        ? OnboardingStep.LinkNetwork
        : undefined;
      const url = oauthService.buildUrl(redirectURL, {
        state: {
          landingEncryptedId: this.landingId,
          onboardingStep: step,
        },
      });
      window.open(url, '_self');
    }
  }

  async networkSubmit(form: NgForm): Promise<void> {
    if (form.invalid) {
      return;
    }

    this.setState(() => {
      this.errorMessage = '';
      this.loading = true;
    });

    try {
      const userNetwork = form.controls['userNetwork'].value.trim();
      this.influencer = await this._service.addUserNetwork(userNetwork);

      this.setState(() => {
        this._currentStep = NetworkLinkingStep.ConfirmationStep;
      });
    } catch (e) {
      this.setState(() => {
        this.errorMessage = signInWithInstagramErrorMsg(e);
      });
    } finally {
      this.setState(() => (this.loading = false));
    }
  }

  async confirmProfile(): Promise<void> {
    if (this.influencer) {
      this.setState(() => {
        this.errorMessage = '';
        this.loading = true;
      });

      try {
        await this._service.confirmProfile(this.influencer);
        // if confirmation is done with success, we navigate to home
        this.success$.next();
      } catch (e) {
        this.setState(() => {
          this.errorMessage = confirmSignInWithInstaErrorMsg(
            this.influencer,
            e,
          );
        });
      } finally {
        this.setState(() => {
          this.loading = false;
        });
      }
    }
  }

  goToSignStep(): void {
    this.setState(
      () => (
        (this.errorMessage = ''),
        (this._currentStep = NetworkLinkingStep.SignInStep)
      ),
    );
  }

  declineProfile(): void {
    this.setState(() => (this._currentStep = NetworkLinkingStep.LinkingStep));
  }

  get isSignInStep(): boolean {
    return this._currentStep === NetworkLinkingStep.SignInStep;
  }

  get isLinkingStep(): boolean {
    return this._currentStep === NetworkLinkingStep.LinkingStep;
  }

  get isConfirmationStep(): boolean {
    return this._currentStep === NetworkLinkingStep.ConfirmationStep;
  }

  get infuencerUsername(): string {
    return this.influencer?.userName ?? '';
  }
}

enum NetworkLinkingStep {
  SignInStep,
  LinkingStep,
  ConfirmationStep,
}
