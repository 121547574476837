export const environment = {
  isProduction: false,
  enableAngularProdMode: true,
  name: 'staging',
  apiHost: 'https://api-staging.lefty.io',
  sentryDsn: 'https://d6cc48aa45cc4e25962955e087719152@sentry.lefty.io//4',
  tiktokAuthKey: 'awthgl7cx5il7wy9',
  youtube: {
    clientId:
      '631454761349-5tsmr125bcuep86m9cr5h36nicvp6q1k.apps.googleusercontent.com',
    clientSecret: '2IIU4W2XE32ZLANoJX0H_X-Z',
    scope: 'https://www.googleapis.com/auth/youtube.readonly',
  },
  twitter: {
    clientId: 'U09mYlRfZTVIc3pKd1JWYXhiX1M6MTpjaQ',
    clientSecrect: 'FFD7bwxmW1Ci3f4Em-_GuMP5UtRSMM-gn0To0NzPz0cjG0cmom',
    scope: 'tweet.read users.read follows.read follows.write offline.access',
  },
  datadog: {
    applicationId: 'b93632f9-7b69-4079-8fd1-e0f8986e9811',
    clientToken: 'pubb3354f742a2c59c4b0879b2c085ca7b1',
  },
  facebookClientId: '668533529958152',
  facebookSecret: '842de995076249d32d307d65ade689dc',
  firebase: {
    apiKey: 'AIzaSyDMPv47eH1hCcaMd8nnzonNSs1wcGMsqP8',
    authDomain: 'leftyv2-1136.firebaseapp.com',
    databaseURL: 'https://leftyv2-1136.firebaseio.com',
    projectId: 'leftyv2-1136',
    storageBucket: 'leftyv2-1136.appspot.com',
    messagingSenderId: '631454761349',
    appId: '1:631454761349:web:96974fd72a9c04b61e44ec',
  },
};
