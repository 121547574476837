import { RouteParams, parseEnumString } from '@frontend2/core';
import {
  LINK_NETWORK_PATH,
  ONBOARDING_DONE_PATH,
  ONBOARDING_INSIGHTS_PATH,
  ONBOARDING_PAYMENT_PATH,
  ONBOARDING_PRODUCT_SEEDING_PATH,
} from '../core/utils';
import { RequirementType } from './collaboration/collaboration.models';

export const ONBOARDING_STEP_KEY = 'step';
export const ONBOARDING_DONE_KEY = 'onboarding-done';

export enum OnboardingStep {
  Unknown,
  LinkNetwork,
  InstagramInsights,
  PaymentDetails,
  ProductSeeding,
  Done,
}

export function parseOnboardingStepFromRouteParams(
  params: RouteParams,
): OnboardingStep {
  return (
    parseEnumString(
      OnboardingStep,
      params.queryParameters[ONBOARDING_STEP_KEY],
    ) ?? OnboardingStep.Unknown
  );
}

export function getOnboardingStepPath(step: OnboardingStep): string {
  switch (step) {
    case OnboardingStep.LinkNetwork:
      return LINK_NETWORK_PATH;
    case OnboardingStep.InstagramInsights:
      return ONBOARDING_INSIGHTS_PATH;
    case OnboardingStep.PaymentDetails:
      return ONBOARDING_PAYMENT_PATH;
    case OnboardingStep.ProductSeeding:
      return ONBOARDING_PRODUCT_SEEDING_PATH;
    case OnboardingStep.Done:
      return ONBOARDING_DONE_PATH;
    default:
      return '';
  }
}

export function readableOnboardingStep(value: OnboardingStep): string {
  switch (value) {
    case OnboardingStep.LinkNetwork:
      return $localize`Link network`;
    case OnboardingStep.InstagramInsights:
      return $localize`Instagram insights`;
    case OnboardingStep.PaymentDetails:
      return $localize`Payment details`;
    case OnboardingStep.ProductSeeding:
      return $localize`Products & delivery address`;
    default:
      return $localize`Requested info`;
  }
}

export function getOnboardingStepTitle(value: OnboardingStep): string {
  switch (value) {
    case OnboardingStep.LinkNetwork:
      return $localize`Identify your creator account`;
    case OnboardingStep.InstagramInsights:
      return $localize`Share your Instagram Insights`;
    case OnboardingStep.PaymentDetails:
      return $localize`Add your payment details`;
    case OnboardingStep.ProductSeeding:
      return $localize`Choose the products you want to receive and your delivery address`;
    default:
      return $localize`Requested info`;
  }
}

export function requirementToOnboardingStep(
  req: RequirementType,
): OnboardingStep {
  switch (req) {
    case RequirementType.InstagramInsights:
      return OnboardingStep.InstagramInsights;
    case RequirementType.PaymentDetails:
      return OnboardingStep.PaymentDetails;
    case RequirementType.ProductSeeding:
      return OnboardingStep.ProductSeeding;
    default:
      return OnboardingStep.Unknown;
  }
}
