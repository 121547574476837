import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { Messages } from '@frontend2/core';
import {
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  TOAST_TYPE_LOADING,
  TOAST_TYPE_SUCCESS,
  Toast,
} from './toast.models';
import { LeftyProgressComponent } from '../loading.component';
import { NgIf } from '@angular/common';
import { LeftyIconComponent } from '../icon/icon.component';

@Component({
  selector: 'lefty-toast',
  styleUrls: ['toast.component.scss'],
  template: `
    <div class="toast">
      <div class="content">
        <lefty-toast-content [toast]="toast"></lefty-toast-content>
      </div>
      <button
        class="close small"
        (click)="clickOnClose($event)"
      >
        {{ closeText }}
      </button>
    </div>
    <div
      class="progress"
      *ngIf="loading"
    >
      <lefty-progress
        [indeterminate]="isIndeterminate"
        [activeProgress]="loadingProgress"
      >
      </lefty-progress>
    </div>
  `,
  standalone: true,
  imports: [
    forwardRef(() => LeftyToastContentComponent),
    NgIf,
    LeftyProgressComponent,
  ],
})
export class LeftyToastComponent implements OnDestroy {
  @Output()
  readonly closeToast$ = new EventEmitter<Toast>();

  @Input()
  toast?: Toast;

  @HostBinding('class.clickable')
  get clickable(): boolean {
    return this.toast?.onClick !== undefined;
  }

  @HostListener('click')
  click(): void {
    if (this.toast?.onClick) {
      this.toast.onClick(this.toast);
    }
  }

  clickOnClose(event: Event): void {
    event.stopPropagation();
    this.closeToast$.emit(this.toast);
  }

  get loading(): boolean {
    return this.toast?.type === 'loading';
  }

  get isIndeterminate(): boolean {
    return this.toast?.progress === undefined;
  }

  // even when indeterminate, <material-progress> need a
  // non null value for [activeProgress]
  get loadingProgress(): number {
    return this.toast?.progress ?? 0;
  }

  ngOnDestroy(): void {
    this.closeToast$.complete();
  }

  get closeText(): string {
    return this.loading ? Messages.hide : Messages.close;
  }
}

/// change toast icon depending on his type
/// dynamic-component are not necessary here
@Component({
  selector: 'lefty-toast-content',
  template: `
    <div class="flex row vCenter">
      @if (isSuccess) {
        <div class="toast-html-icon success-icon">
          <lefty-icon
            size="medium"
            icon="success_feedback"
          ></lefty-icon>
        </div>
      } @else if (isError) {
        <div class="toast-html-icon error-icon">
          <lefty-icon
            size="medium"
            icon="error_feedback"
          ></lefty-icon>
        </div>
      } @else if (isLoading) {
        <div class="toast-html-icon loading-icon">
          <lefty-icon
            size="medium"
            icon="refresh"
          ></lefty-icon>
        </div>
      } @else if (isInfo) {
        <div class="toast-html-icon info-icon">
          <lefty-icon
            size="medium"
            icon="warning_feedback"
          ></lefty-icon>
        </div>
      }

      <div
        class="text"
        [innerHTML]="text"
      ></div>
    </div>
  `,
  styleUrls: ['toast-content.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyIconComponent],
})
export class LeftyToastContentComponent {
  private _text = '';

  get text(): string {
    return this._text;
  }

  @ViewChild('content')
  contentEl?: ElementRef<HTMLDivElement>;

  private _toast?: Toast;
  get toast(): Toast | undefined {
    return this._toast;
  }

  @Input()
  set toast(toast: Toast | undefined) {
    this._toast = toast;
    this._text = this._toast?.text ?? '';
  }

  get isSuccess(): boolean {
    return this.toast?.type === TOAST_TYPE_SUCCESS;
  }

  get isError(): boolean {
    return this.toast?.type === TOAST_TYPE_ERROR;
  }

  get isLoading(): boolean {
    return this.toast?.type === TOAST_TYPE_LOADING;
  }

  get isInfo(): boolean {
    return this.toast?.type === TOAST_TYPE_INFO;
  }
}
