<h1 class="title">
  {{ title(selectedNetwork) }}
</h1>

<div
  class="sign-in-step"
  *ngIf="isSignInStep"
>
  <lefty-feedback
    type="error"
    [hideIcon]="true"
    *ngIf="hasErrorMessage"
  >
    {{ errorMessage }}
  </lefty-feedback>

  <button
    *ngFor="let network of supportedNetworks"
    class="large outline"
    (click)="signInWith(network)"
  >
    <network-icon [network]="network"></network-icon>
    <div class="action-msg">
      {{ signInWithMessage(network) }}
    </div>
  </button>
</div>

<div
  class="linking-step"
  *ngIf="isLinkingStep"
>
  <form
    #form="ngForm"
    (ngSubmit)="networkSubmit(form)"
  >
    <lefty-feedback
      type="error"
      [hideIcon]="true"
      *ngIf="hasErrorMessage"
    >
      {{ errorMessage }}
    </lefty-feedback>

    <lefty-form-input
      [label]="userNetworkInput(selectedNetwork)"
      autoFocus
      ngModel
      i18n-placeholder
      placeholder="selenagomez or www.instagram.com/selenagomez"
      name="userNetwork"
      [required]="true"
    >
    </lefty-form-input>

    <input
      hidden
      [disabled]="loading"
      type="submit"
    />
  </form>

  <div class="actions">
    <button
      leftyButton="primary"
      size="large"
      [loading]="loading"
      [disabled]="loading"
      (click)="networkSubmit(form)"
    >
      {{ submittingMsg(loading) }}
    </button>
    <button
      leftyButton="secondary"
      size="large"
      [disabled]="loading"
      (click)="goToSignStep()"
      i18n
    >
      Back to networks
    </button>
  </div>
</div>

<div
  class="confirmation-step"
  *ngIf="isConfirmationStep"
>
  <lefty-feedback
    type="error"
    [hideIcon]="true"
    *ngIf="hasErrorMessage"
  >
    {{ errorMessage }}
  </lefty-feedback>

  <div class="profile flex">
    <profile-picture [creator]="influencer"> </profile-picture>
    <div class="infos">
      <network-icon [network]="selectedNetwork"></network-icon>
      <h3 class="username">{{ infuencerUsername }}</h3>
      <div class="body-1 followers">
        {{ followers }}
      </div>
    </div>
  </div>

  <div class="actions">
    <button
      leftyButton="primary"
      size="large"
      [loading]="loading"
      (click)="confirmProfile()"
      [disabled]="loading"
      i18n
    >
      This is me
    </button>
    <button
      leftyButton="secondary"
      size="large"
      (click)="declineProfile()"
      i18n
    >
      This is not me
    </button>
  </div>
</div>
