/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeftyEventsModule } from '@frontend2/ui';
import { NestorEventsBus } from './common/events/events';
import {
  FACEBOOK_REDIRECT_PATH,
  LANDING_PATH,
  LINK_NETWORK_PATH,
  LOGIN_PATH,
  NOT_FOUND_PATH,
  REQUIRE_PAYMENT_INFO_PATH,
  SIGNUP_PATH,
  STRIPE_REDIRECT_PATH,
  TIKTOK_REDIRECT_PATH,
  TWITTER_REDIRECT_PATH,
  YOUTUBE_REDIRECT_PATH,
} from './core/utils';
@NgModule({
  imports: [
    LeftyEventsModule.forRoot(NestorEventsBus),
    RouterModule.forRoot([
      {
        path: LOGIN_PATH,
        loadChildren: () =>
          import('./routes/login-route/login.module').then(
            (lib) => lib.LoginModule,
          ),
      },
      {
        path: SIGNUP_PATH,
        loadChildren: () =>
          import('./routes/signup-route/signup.module').then(
            (lib) => lib.SignUpModule,
          ),
      },
      {
        path: 'reset_password',
        loadChildren: () =>
          import('./routes/reset-password-route/reset.password.module').then(
            (lib) => lib.ResetPasswordModule,
          ),
      },

      {
        path: LANDING_PATH,
        loadChildren: () =>
          import('./routes/landing-route/landing.module').then(
            (lib) => lib.LandingModule,
          ),
      },
      {
        path: LINK_NETWORK_PATH,
        loadChildren: () =>
          import('./routes/network-linking-route/network.linking.module').then(
            (lib) => lib.NetworkLinkingModule,
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./routes/home-route/home.module').then(
            (lib) => lib.HomeModule,
          ),
      },
      {
        path: FACEBOOK_REDIRECT_PATH,
        loadChildren: () =>
          import(
            './routes/oauth-redirect/facebook-redirect-route/facebook.redirect.module'
          ).then((lib) => lib.FacebookRedirectModule),
      },
      {
        path: TIKTOK_REDIRECT_PATH,
        loadChildren: () =>
          import(
            './routes/oauth-redirect/tiktok-redirect-route/tiktok.redirect.module'
          ).then((lib) => lib.TikTokRedirectModule),
      },
      {
        path: YOUTUBE_REDIRECT_PATH,
        loadChildren: () =>
          import(
            './routes/oauth-redirect/youtube-redirect-route/youtube.redirect.module'
          ).then((lib) => lib.YoutubeRedirectModule),
      },
      {
        path: TWITTER_REDIRECT_PATH,
        loadChildren: () =>
          import(
            './routes/oauth-redirect/twitter-redirect-route/twitter.redirect.module'
          ).then((lib) => lib.TwitterRedirectModule),
      },
      {
        path: STRIPE_REDIRECT_PATH,
        loadChildren: () =>
          import(
            './routes/oauth-redirect/stripe-redirect-route/stripe.redirect.module'
          ).then((lib) => lib.StripeRedirectModule),
      },
      {
        path: REQUIRE_PAYMENT_INFO_PATH,
        loadChildren: () =>
          import(
            './routes/require-payment-info/require-payment-info.module'
          ).then((lib) => lib.RequirePaymentInfoModule),
      },
      {
        path: NOT_FOUND_PATH,
        loadComponent: () =>
          import('./routes/404/404.route').then(
            (m) => m.NetworkPageNotFoundComponent,
          ),
      },
      { path: '**', redirectTo: NOT_FOUND_PATH },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRouterModule {}
