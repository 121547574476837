import { MapStringString } from '@frontend2/core';
import { environment } from '../../../environments/environment';
import { TIKTOK_OAUTH_URL, TIKTOK_REDIRECT_PATH } from '../../core/utils';
import {
  NetworkOAuthService,
  OauthState,
  applyOAuthStateToUrl,
} from './network-oauth.service';

const TIKTOK_OAUTH_CODE_KEY = 'code';
const TIKTOK_SCOPES = ['user.info.profile'];

export const TIKTOK_OAUTH_ERROR_KEY = 'tiktok_oauth_error';

interface OAuthUrlRequest extends Record<string, string> {
  readonly client_key: string;
  readonly redirect_uri: string;
  readonly scope: string;
  readonly response_type: string;
}

export class TikTokOAuthService implements NetworkOAuthService {
  buildUrl(redirectUri: URL, args?: { state?: OauthState }): URL {
    const csrfState = Math.random().toString(36).substring(2);
    document.cookie = 'csrfState=' + csrfState + 'max-age=60000';

    const url = new URL(TIKTOK_OAUTH_URL);

    const request: OAuthUrlRequest = {
      client_key: environment.tiktokAuthKey,
      redirect_uri: redirectUri.toString(),
      scope: TIKTOK_SCOPES.join(','),
      response_type: 'code',
    };

    url.search = new URLSearchParams(request).toString();

    let state = args?.state ?? {};
    state = { ...state, csrf: csrfState };

    return applyOAuthStateToUrl(url, state);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseCodeFromQueryParams(queryParams: MapStringString): string {
    return queryParams[TIKTOK_OAUTH_CODE_KEY] ?? '';
  }

  getRedirectURL(): URL {
    return new URL(window.location.origin + '/' + TIKTOK_REDIRECT_PATH);
  }
}
