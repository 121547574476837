import { Inject, Injectable, computed } from '@angular/core';
import { BoolValue, Empty, StringValue } from '@bufbuild/protobuf';
import {
  RegisteredAuthorClient,
  RegisteredAuthorClientProvider,
} from '@frontend2/api';
import { posthogIdentify, setupTracingUserContext } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { ResetPasswordRequest } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { StripeRegion } from '@frontend2/proto/librarian/proto/payments_pb';
import {
  LoggedAuthorV2,
  LoggedAuthorV2_AuthorNetworkInfo,
  LoggedAuthorV2_ChatInfo,
  LoginInfluencerRequest,
  SignupInfluencerRequest,
  StripeRequest,
  StripeStatus,
} from '@frontend2/proto/librarian/proto/registered_author_pb';
import { Bloc } from '@frontend2/ui';
import { LeftyChatBloc } from '../routes/home-route/conversations-route/lefty-chat.bloc';
import {
  creatorFromAuthorNetworkInfo,
  getEmptyAuthorCreator,
} from '../routes/home-route/settings-route/settings.helpers';
import { getAuthorNetworkInfo, hasLinkedNetwork } from './auth.helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthBloc extends Bloc<LoggedAuthorV2> {
  constructor(
    @Inject(RegisteredAuthorClientProvider)
    private registeredAuthor: RegisteredAuthorClient,
    private leftyChatBloc: LeftyChatBloc,
  ) {
    super(new LoggedAuthorV2());
  }

  readonly user = this.state;
  readonly isLogged = computed(() => this.user().logged);

  readonly authorNetworkInfos = computed(() => this.state().authors);

  getAuthorNetworkInfo(network: Network): LoggedAuthorV2_AuthorNetworkInfo {
    return getAuthorNetworkInfo(this.user(), network);
  }

  readonly instaNetworkInfo = computed(() =>
    this.getAuthorNetworkInfo(Network.INSTA),
  );

  readonly hasLinkedNetwork = computed(
    () => this.authorNetworkInfos().length > 0,
  );

  readonly hasLinkedInsta = computed(() =>
    hasLinkedNetwork(this.user(), Network.INSTA),
  );

  readonly hasSharedInstaInsights = computed(() => this.user().hasValidToken);

  readonly stripeRegionStatuses = computed(
    () => this.user().stripeRegionStatuses,
  );

  getStripeStatus(region: StripeRegion): StripeStatus {
    return (
      this.stripeRegionStatuses().find((s) => s.stripeRegion === region)
        ?.stripeStatus ?? StripeStatus.NO_STRIPE_ACCOUNT
    );
  }

  hasNoPaymentInfo(region: StripeRegion): boolean {
    return this.getStripeStatus(region) === StripeStatus.NO_STRIPE_ACCOUNT;
  }

  hasInvalidPaymentInfo(region: StripeRegion): boolean {
    return this.getStripeStatus(region) === StripeStatus.NO_KYC_STRIPE_ACCOUNT;
  }

  hasPaymentInfo(region: StripeRegion): boolean {
    return this.getStripeStatus(region) === StripeStatus.VALID_STRIPE_ACCOUNT;
  }

  readonly chatInfo = computed(
    () => this.user().chatInfo ?? new LoggedAuthorV2_ChatInfo(),
  );

  readonly influencer = computed(() => {
    const user = this.user();

    if (user.authors.length === 0) {
      return getEmptyAuthorCreator();
    }

    return creatorFromAuthorNetworkInfo(user.authors[0], user.email);
  });

  readonly influencerNetwork = computed(() => this.influencer().network);
  readonly influencerNetworkUrl = computed(() => this.influencer().networkUrl);
  readonly influencerEmail = computed(() => this.influencer().email);
  readonly creator = computed(() => this.influencer().creator);

  readonly username = computed(() => this.creator().userName);
  readonly followersCount = computed(() => this.creator().followers ?? 0);

  signup(email: string, password: string): Promise<Empty> {
    const form = new SignupInfluencerRequest({
      email: email,
      password: password,
    });
    return this.registeredAuthor.emailSignupV2(form);
  }

  login(email: string, password: string): Promise<Empty> {
    const form = new LoginInfluencerRequest({
      email: email,
      password: password,
    });
    return this.registeredAuthor.emailLoginV2(form);
  }

  sendResetPasswordLinkAPI(email: string): Promise<Empty> {
    return this.registeredAuthor.sendResetPasswordLinkAPI(
      new StringValue({ value: email }),
    );
  }

  isResetPasswordTokenValidAPI(token: string): Promise<BoolValue> {
    return this.registeredAuthor.isResetPasswordTokenValidAPI(
      new StringValue({ value: token }),
    );
  }

  async resetPasswordAPI(
    token: string,
    password: string,
  ): Promise<LoggedAuthorV2> {
    await this.registeredAuthor.resetPasswordAPI(
      new ResetPasswordRequest({ token: token, password: password }),
    );

    return this.checkIsLogged();
  }

  async checkIsLogged(): Promise<LoggedAuthorV2> {
    const user = await this.registeredAuthor.isAuthorLoggedV2(new Empty());
    setupTracingUserContext(user);
    posthogIdentify(user);

    this.setState(user);
    if (this.isLogged() && this.hasLinkedNetwork()) {
      this.leftyChatBloc.startSessionFromAuth(this.username(), this.chatInfo());
    }

    return user;
  }

  // this method is called on the Stripe KYC callback
  // author might leave the kyc flow before finishing it,
  // so we need to check if the KYC process is done or not yet
  async verifyStripe(stripeRegion: StripeRegion): Promise<boolean> {
    const isKyc = await this.registeredAuthor.verifyAuthorStripe(
      new StripeRequest({
        stripeRegion,
      }),
    );

    // we reload isAuthorLogged here to get the updated stripe status, that will be used everywhere in the app
    await this.checkIsLogged();

    return isKyc.value;
  }

  async logout(): Promise<void> {
    await this.registeredAuthor.logout(new Empty());
  }
}
